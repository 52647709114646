<template>
  <v-form
    @submit.prevent="submit"
    @reset="$router.push('/')"
    class="loginFormOld pa-10 text-left"
  >
    <div class="pt-4 pb-10 text-center">
      <img
        src="@/assets/img/logo.png"
        class="logoLoginOld"
      >
      <h4>Войдите, чтобы продолжить</h4>
    </div>
    <v-text-field
      id="input-1"
      v-model="email"
      solo
      placeholder="Почта"
      :error-messages="checkErrors('email')"
    ></v-text-field>
    <v-text-field
      id="input-2"
      v-model="password"
      solo
      type="password"
      placeholder="Пароль"
      :error-messages="checkErrors('password')"
    ></v-text-field>
    <div class="text-right">
      <v-btn
        type="submit"
        class="mr-2"
        small
        left
        color="primary"
        :loading="isLoading"
      >Войти
      </v-btn>
    </div>

  </v-form>
</template>

<script>

import { required } from 'vuelidate/lib/validators'
import validationErrors from '@/components/mixins/validationErrors'

export default {
  name: 'Login',
  mixins: [validationErrors],
  validations: {
    email: { required },
    password: { required }
  },
  data () {
    return {
      email: '',
      password: '',
      isLoading: false
    }
  },
  methods: {
    async submit () {
      if (!this.isLoading) {
        this.isLoading = true
        this.$v.$touch()
        if (!this.$v.$anyError) {
          await this.$store.dispatch('auth/login', { email: this.email, password: this.password })
        }
        this.isLoading = false
      }
      return true
    }
  }
}
</script>

<style>
  .loginFormOld {
    padding: 20px;
    background: #fff;
    box-shadow: 1px 12px 12px rgba(0, 0, 0, 0.2);
  }
</style>

<style scoped>
  .logoLoginOld {
    width: 100%;
    opacity: 0.8;
    margin-bottom: 15px;
  }
</style>
